header {
  height: 95vh;
  padding-top: 5rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

.Typist {
  text-align: center;
  font-size: 76px;
  margin-top: -40px;
  padding-left: 42px;
}

.Typist .Cursor--blinking {
  padding-left: 10px;
}

.intro-name {
  font-weight: bold;
}

.intro-subtitle {
  font-weight: 300;
  font-size: 42px;
  margin-top: -36px;
}

.intro-desc {
  font-size: 20px;
  max-width: 70%;
  text-align: justify center;
  margin: 0rem 25rem;
}

/* === CTA === */

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

.inline-icon{
  vertical-align: middle;
  font-size: 20px !important;
}

/* === Socials === */

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 2rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* === Image === */

.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 22rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 20rem;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  
  header {
    height: 55vh;
  }

  .Typist{
    font-size: 68px;
  }

  .intro-subtitle {
    width: 100%;
  }

  .intro-desc {
    width: 86%;
    margin-left: 6rem;
  }

  .me{
    display: none;
  }
  
}

@media screen and (max-width: 600px) {
  header {
    height: 85vh;
  }

  .intro-subtitle {
    font-size: 30px;
    width: 100%;
    margin-top: -24px;
  }

  .intro-desc {
    width: 90%;
    margin-left: 4rem;
    font-size: 15px;
  }

  .header__socials,
  .scroll__down, .me {
    display: none;
  }

  .Typist {
    text-align: center;
    font-size: 48px;
    margin-top: -40px;
    padding-left: 42px;
  }
}
