.box {
  padding-left: 22%;
}

.joblist-job-title {
  font-size: 28px;
  font-weight: bold;
  color: var(--color-white);
}

.joblist-job-company {
  font-size: 28px;
  color: var(--color-primary);
  font-weight: bold;
}

.joblist-duration {
  font-size: 18px;
  color: var(--color-light);
}

#vertical-tabpanel {
  margin-top: -28px;
  padding-left: 50px;
}

.MuiTab-wrapper {
  font-size: 16px;
  text-align: left;
  align-items: flex-start !important;
}

.MuiButtonBase-root {
  outline: none !important;
  color: var(--color-light) !important;
}

.PrivateTabIndicator-colorSecondary-5 {
  background-color: var(--color-primary) !important;
}
.jss5 {
  background-color: var(--color-primary) !important;
}
.makeStyles-tabs-2 {
  border-right: 1px solid var(--color-primary) !important;
}
#experience .MuiTab-root {
  padding: 6px 20px;
}
.MuiButtonBase-root:focus {
  color: var(--color-primary) !important;
}

ul {
  list-style: none;
  margin-left: -40px;
}

.job-description li::before {
  content: ">    ";
  color: var(--color-primary);
  position: absolute;
  left: 0;
}

.job-description li {
  position: relative;
  padding-left: 30px;
  padding-bottom: 16px;
  font-size: 18px;
  color: var(--color-light);
}

.job-description {
  padding-top: 20px;
  max-width: 650px;
  font-size: 20px;
}

.tabs {
  border-right: 1px solid transparent;
  background: transparent;
}

@media screen and (max-width: 1024px) {

}
@media screen and (max-width: 600px) {
  .jss1 {
    height: unset !important;
    flex-direction: column !important;
  }

  .makeStyles-root-1 {
    height: unset !important;
    width: unset;
    flex-direction: column;
  }

  .joblist-job-title {
    font-size: 22px;
  }

  .joblist-job-company {
    font-size: 22px;
  }
  .box {
    height: 100vh !important;
  }
}
