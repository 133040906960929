@import url("https://fonts.googleapis.com/css2?family=Cantarell&family=Fira+Sans:wght@300;400&family=Oxygen:wght@300;400&family=Roboto:wght@300;400&family=Source+Code+Pro:wght@300;400&family=Ubuntu:wght@300;400&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #1f1f38;
  --color-bg-variant: #2c2c6c;
  --color-primary: #4db5ff;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container--wid-sm: 90%;

  --transition: all 400ms ease;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: monospace, "Source Code Pro", cursive, "Cantarell", "Fira Sans",
    "Roboto", "Oxygen", "Ubuntu", sans-serif;
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
  background-image: url(./assets/bg.png);
}

/* === General Styles === */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

.section__title {
  text-align: center;
  color: var(--color-primary);
  font-weight: bold;
  padding-bottom: 2rem;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section__title::after {
  content: "";
  width: 300px;
  height: 1px;
  display: block;
  background: var(--color-light);
  margin-left: 20px;
}
.section__title::before {
  content: "";
  width: 300px;
  height: 1px;
  display: block;
  background: var(--color-light);
  gap: 200px;
  margin-right: 20px;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

b {
  color: var(--color-primary);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
  font-size: 20px;
  text-align: center;
  align-items: center;
  font-family: monospace, "Source Code Pro", cursive, "Cantarell", "Fira Sans",
    "Oxygen", "Roboto", "Ubuntu", sans-serif;
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* === Media Styles === */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }
}
